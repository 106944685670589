import { post } from '@/utils/request'

//获取目录
export function getLocList(data) {
    return post('/edu/edu_address/list', data)
}
// 省市区列表
export function provinceList(data) {
    return post('/edu/edu_address/getAreaList', data)
}
//添加服务地址
export function addLoc(data) {
    return post('/edu/edu_address/addAddress', data)
}
//服务地址回显
export function getInfo(data) {
    return post('/edu/edu_address/getAddressInfo', data)
}

//教务类型编辑
export function editLoc(data) {
    return post('/edu/edu_address/editAddress', data)
}
//教务类型删除
export function delLoc(data) {
    return post('/edu/edu_address/delAddress', data)
}


